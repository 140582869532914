import moment from 'moment'

export default {
    name: 'QuickReports',
    data: () => ({
        reports: [{ pathname: 'packout', title: 'Packout' }],
        hone: '',
        qpackoutdate: false,
        qselectedpackoutdate: '',
        qgetquoteinfo: false,
        runningmounted: true,
        showdatebutton: false
    }),
    watch: {
        //        prodrecs() {
        //            if (this.prodrecs.length > 0) {
        //                this.disablerouteaction = false
        //            } else {
        //                this.disablerouteaction = true
        //            }
        //        }
    },
    async mounted() {
        let haverep = this.reports.find(o => o.pathname === this.$route.params.repname)
        this.runningmounted = true

        if (haverep) {
            this.hone = haverep.title
            this.showdatebutton = true
            this.grabreport(2)
        } else {
            this.hone = 'No Valid Report'
        }
    },
    computed: {
        qpackoutdates: { get() { return this.$store.getters['Reporting/getPackoutDates'] } },
        prodrecs: { get() { return this.$store.getters['Reporting/getProdRecs'] }, set(val) { this.$store.commit('Reporting/SET_PRODRECS', val) } },
    },
    methods: {
        /* ---
        !!
         grabreport and grabreportgo are dupes of the authenticated functions in reporting.js
         need to move to the store
        !!
        --- */
        grabreport: function (e) {
            if (e == '2') {
                /* --- 200 gets dates for select list --- */
                this.grabreportgo(200)
                this.qpackoutdate = true
                this.$nextTick(() => this.selectedstatus = '2')
            } else {
                /* --- 100 = ship date selected for packout, need to reset --- */
                if (e === 100) {
                    this.runningmounted = false
                    this.qpackoutdate = false
                    this.qgetquoteinfo = true

                    if (moment.unix(this.qselectedpackoutdate).utcOffset(-5).format('MM/DD/YYYY') !== 'Invalid date') {
                        this.packoutdatedisplay = moment.unix(this.qselectedpackoutdate).utcOffset(-5).format('MM/DD/YYYY')
                    } else {
                        this.qpackoutdatedisplay = this.qselectedpackoutdate
                    }
                    e = '2'
                }

                this.grabreportgo(e)
            }
        },
        grabreportgo: function (e) {
            /* ---
              there is a duplicate of this in /home/ubuntu/scripts/reports.js on the web server
              if significant updates are made here, make them there too!!
            --- */

            this.$store.dispatch('Reporting/fetchProd', {
                op: 'getreport',
                reptype: e,
                packoutdate: this.qselectedpackoutdate,
                getquoteinfo: this.qgetquoteinfo
            }, { root: true })
                .then(() => {
                    this.qgetquoteinfo = false
                    if (!this.runningmounted) {
                        this.$store.dispatch('Sgs/sendSheet', { op: this.selectedstatus, recs: this.prodrecs, packoutdatedisplay: this.packoutdatedisplay })
                            .then(() => {
                                this.runningmounted = true
                            })
                    }
                })
                .catch(err => {
                    console.log('FETCH PROD ERR')
                    console.log(err)
                })
        },
    }
}